import { useRef, useState, useCallback, useEffect } from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { setRegion } from "src/Common/Redux/Actions";

import ShopeePayLogo from "src/Common/Assets/Icons/ShopeeLogo/shopeePayLogo2022.svg";

import "./style.less";
import { EXTRA_BUTTON, MENU_LIST } from "./Constant";
import { useOutsideAlerter } from "./Hooks/useOutsideAlerter"

const MobileNav = ({
  phoneMenuOpen,
  setPhoneMenuOpen,
  menu,
  renderNavItem,
}) => {
  const menuContainerRef = useRef(null);

  useOutsideAlerter(menuContainerRef, (target) => {
    if (!target.classList.contains("hamburger-button")) {
      setPhoneMenuOpen(false);
    }
  });
  
  return (
    <div className="mobile-container">
      <button
        type="button"
        className={`menu-toggle ${phoneMenuOpen ? "open" : ""} hamburger-button`}
        onClick={() => setPhoneMenuOpen((prev) => !prev)}
      >
        <div className="bar hamburger-button" />
        <div className="bar hamburger-button" />
        <div className="bar hamburger-button" />
      </button>

      <div className={`menu-list-phone ${phoneMenuOpen ? "open" : ""} `}>
        <div className="menu-container" ref={menuContainerRef}>
          {menu.map((item) => renderNavItem(item, true))}
          <Link
            path={EXTRA_BUTTON.path}
            title={EXTRA_BUTTON.title}
            className="extra-button"
            // style={{alignSelf: 'center'}}
          />
        </div>
      </div>
    </div>
  );
};

const Link = ({ path, title, onClick, style, className = "" }) => (
  path.includes("http") ? (
    <a href={path} target="_blank" >
      <div className={`menu-item ${className}`}>{ title }</div>
    </a>
  ) : (
    <NavLink to={path} onClick={onClick} style={style}>
      <div className={`menu-item ${className}`}>{ title }</div>
    </NavLink>
  )
)

export const Navbar = () => {
    const [phoneMenuOpen, setPhoneMenuOpen] = useState(false);
    const dispatch = useDispatch();
  const menu = MENU_LIST;
  const initialOpenDropdown = menu.reduce(
    (acc, item) => {
      acc[item.title] = false;
      return acc;
    },
    {}
  );

  const [openDropdown, setOpenDropdown] = useState(
    initialOpenDropdown
  );

  const resourcesDropdownRef = useRef(null);

  const location = useLocation();

  useOutsideAlerter(resourcesDropdownRef, (target) => {
    if (
      target.textContent !== "Resources" &&
      !target.classList.contains("dropdown-arrow") &&
      !target.classList.contains("bar")
    ) {
      setOpenDropdown((prev) => ({ ...prev, Resources: false }));
      if (target.classList.contains("menu-item")) {
        target?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
      }
    }
  });

  const renderNavItem = (item, isPhone) =>
    item.children ? (
      <div
        className="dropdown"
        key={item.path}
        ref={
          item.title === "Resources"
            ? resourcesDropdownRef
            : undefined
        }
      >
        <div
          className={`menu-item ${
            location.pathname?.startsWith(item.path) ? "active" : ""
          }`}
          onClick={() =>
            setOpenDropdown((prev) => ({
              [item.title]: !prev[item.title],
            }))
          }
        >
          {item.title}
          <span
            className={`dropdown-arrow ${
              openDropdown[item.title] ? "open" : ""
            }`}
          >
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.31355 0.619827C5.67567 0.201685 6.32433 0.201685 6.68645 0.619827L11.7766 6.49742C12.2859 7.08554 11.8682 8 11.0901 8L0.90985 8C0.131842 8 -0.285928 7.08554 0.223399 6.49742L5.31355 0.619827Z"
                fill="black"
                fillOpacity="0.65"
              />
            </svg>
          </span>
        </div>
        <div
          className={`dropdown-content ${
            openDropdown[item.title] ? "open" : ""
          }`}
        >
          {item.children.map((subMenu) => (
            <Link
              path={subMenu.path}
              title={subMenu.title}
              onClick={() => {
                if (isPhone) {
                  setPhoneMenuOpen(!phoneMenuOpen);
                } else {
                  setOpenDropdown((prev) => ({ ...prev, [item.title]: false }));
                }
              }}
            />
          ))}
        </div>
      </div>
    ) : (
      <Link
        path={item.path}
        title={item.title}
        onClick={isPhone ? () => setPhoneMenuOpen(!phoneMenuOpen) : undefined}
      />
    );

    const setRegionOnMount = useCallback(() => {
        dispatch(setRegion('sg'));
    }, [dispatch, 'sg']);

    useEffect(() => {
        setRegionOnMount();
      }, [setRegionOnMount]);
    
  return (
    <nav className="navigation-container new">
      <div className="content">
        <NavLink to="/">
          <img src={ShopeePayLogo} alt="shopeepay logo" className="logo" />
        </NavLink>

        <MobileNav
          phoneMenuOpen={phoneMenuOpen}
          setPhoneMenuOpen={setPhoneMenuOpen}
          menu={menu}
          renderNavItem={renderNavItem}
        />
        <div className="menu-list desktop-container">
          {menu?.map((item) => renderNavItem(item, false))}
          <Link
            path={EXTRA_BUTTON.path}
            title={EXTRA_BUTTON.title}
            className="extra-button"
          />
        </div>
      </div>
    </nav>
  );
};
