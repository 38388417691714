export const MENU_LIST = [
    {
        title: "Home",
        path: "/",
    },
    { title: "Consumer", path: "/consumer" },
    { title: "Merchant", path: "/merchant" },
    { title: "FAQ", path: "/faq" },
    { title: "Career", path: "https://careers.shopee.sg/" },
    {
        title: "Resources",
        children: [
          {
            title: "Merchant Preview",
            path: "https://shopeepay.sg/merchant-demo",
          },
          {
            title: "Integration Guide",
            path: "https://product.shopeepay.com/",
          },
        ],
    },
]

export const EXTRA_BUTTON = {
    title: "Download",
    path: "/download",
};
